@import "main.css";
/*----------Barre de nav ----------------*/

a.dropdown-item:hover {
    background-color: #eee1e1 !important;
}

/*----------LARGEUR DES IMAGES DANS LE BLOC PATCHWORK--------------*/ 
@media (min-width: 990px) 
{ .redirectsCards-div div.row-cols-1 > div.col 
{ width: 33.3333333333% !important; } 
}

/*----------EFFET BLOC PATCHWORK--------------*/ 
@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap');

div.redirectsCards-div {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Philosopher', sans-serif;
    text-transform: uppercase; /* Texte en majuscule */
}

div.redirectsCards-div > .row {
    margin: 0 !important;
    padding: 0 !important;
}

div.redirectsCards-div > .row > .col {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1.2rem; /* Texte plus gros */
    background-color: rgba(217, 195, 176, 0.55); /* Beige en transparence 55% */
    background-blend-mode: multiply;
    filter: grayscale(1); /* Image en niveaux de gris */
    transition: all 0.3s cubic-bezier(.86, .01, .15, .99);
}

div.redirectsCards-div > .row > .col:hover {
    filter: none; /* Restaure l'image en couleurs */
}

/*----------Enlever effet incrustation des espacements--------------*/ 
.b-bloc-divider {
    border: none !important;
    border-width: none !important;
    box-shadow: none !important;
    background-color: #EFF4F5 !important; 
}

.carrousel {
    overflow: hidden;
}

/*----------Logo plus bas sur les haut de pages--------------*/ 
.fondDegradeLogo {
    margin-top: 150px;
}

/*------------------------------------------------Bloc accordéon--------------------------------------*/
.accordion-button:not(.collapsed) {
    color: black !important;  /* couleur de ton texte*/
    background-color: #C2B95D !important;
    box-shadow: inset 0 calc(var(--bs-accordion-border-width)* -1) 0 #19301e !important;
}

.navbar-brand { height: 12vh !important; width: 12vw !important; }
.logo_nav { height: 11vh !important }
